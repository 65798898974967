.error {
    color: red;
    font-size: 13px;
    margin-bottom: 0px;
}
.previewField{
    width: 500px;
}
.previewGroup{
    display: flex;
}
.tddropdown button {
    background-color: transparent !important;
}
.tddropdown button {
    --bs-btn-color: none !important;
    --bs-btn-bg: none;
    --bs-btn-border-color: none;
    --bs-btn-hover-bg: none;
    --bs-btn-hover-border-color: none;
    --bs-btn-hover-color: none;
    --bs-btn-focus-shadow-rgb: none;
    /* --bs-btn-active-color: #fff; */
    --bs-btn-active-bg: none;
    --bs-btn-active-border-color: none;
    --bs-btn-active-shadow: none;
    --bs-btn-disabled-color: none;
    --bs-btn-disabled-bg: none;
    --bs-btn-disabled-border-color: none;
    --bs-btn-hover-color: none;
    --bs-btn-active-color: black
}


.tddropdown::after {
    content: none;
   
}
.iconmargin{
    margin-right:5px
}