.pageContainer {
    display: flex;
    flex-wrap: wrap;
    background: #F8FAFE;
}
.leftContainer {
    width: 16%;
    height: calc(100vh - 72px);
    overflow: hidden;
}
.rightContainer {
    width: 84%;
    padding: 20px 15px;
    height: calc(100vh - 80px);
    overflow-x: hidden;
    overflow-y: auto;
}
.pageOuter
{
    padding: auto;
    height: 100vh;
    overflow: hidden;
}
.graphPage img {
    width: 100%;
}
.graphPage {
    margin-bottom: 27px;
}
.timeShetet {
    background: #0053CD;
    border-radius: 10px;
    padding: 28px;
    display: flex;
    align-items: center;
}
.timeShetet span {
    border: 1px solid #FFFFFF;
    width: 80px;
    height: 80px;
    border-radius: 10px;
    margin-right: 14px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.rightTime h6 {
    font-size: 19px;
    color: #fff;
    margin-bottom: 13px;
}
.rightTime h5 {
    font-size: 48px;
    color: #fff;
    font-weight: 800;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.rightTime h5 label {
    font-size: 13px;
}
.rightTime {
    width: 62%;
}
.secTimesheet {
    background: #48A9F8;
}
.thirdTimesheet{
    background: #1BD084;
}
.rightContainer h2 {
    font-size: 30px;
    font-weight: 600;
    margin: 0 0 23px;
}
.OuterTime
{
    padding-bottom: 25px;
}
.revenueBox {
    background: #fff;
    border: 1px solid #EFEFEF;
    border-radius: 10px;
    padding: 15px;
}
.revenueBoxHead {
    display: flex;
    justify-content: space-between;
    min-height: 50px;
    align-items: center;
}
.revenueBoxHead h6 {
    margin: 0 0 20px 0;
    font-size: 14px;
    color: #141414;
}
.revenueBoxHead span {
    font-size: 12px;
    color: #1DBF73;
    border: 1px solid;
    padding: 4px 8px;
    border-radius: 22px;
    background: #8fe7be1f;
}
.revenueBoxFoter h4 span {
    color: #989CA0;
}
.revenueBoxFoter span {
    color: #0053CD;
    font-weight: 600;
}
.revenueBoxFoter h4 {
    font-size: 13px;
    margin: 0;
}
.revenueBoxFoter {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.revenueBox h5 {
    font-size: 26px;
    font-weight: 700;
}
.baseInfo {
    background: #fff;
    padding: 30px;
}
.baseinfoHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.baseinfoHead h5 {
    font-size: 16px;
    color: #141414;
    font-weight: 700;
    margin: 0;
}
.baseinfoHead button {
    font-size: 14px;
    background: #0053CD;
    color: #fff;
    padding: 8px 13px;
    border-radius: 5px;
    border: 0;
}
.baseinfoHead button img {
    margin-right: 4px;
}
.innerbaseinfo {
    background: #F8FAFE;
    border-radius: 10px;
    padding: 20px 60px;
    margin: 24px 0 0;
    display: flex;
}
.leftInfo h6 {
    font-size: 16px;
    color: #2E2E2E;
    margin: 12px 0 7px;
    text-align: center;
}
.leftInfo {
    text-align: center;
    padding-right: 20px;
}
.leftInfo label {
    background: #0053CD1A;
    padding: 7px 15px;
    border-radius: 30px;
    color: #0053CD;
    font-size: 11px;
    font-weight: 600;
}
.rightInfo h5 {
    font-size: 18px;
    font-weight: 500;
}
.rightInfo h6 {
    color: #0053CD;
    font-size: 16px;
    font-weight: 500;
}
.rightInfo ul {
    margin: 0;
    margin-left: 20px;

}
.rightInfo ul li {
    font-size: 14px;
    color: #000000;
    font-weight: 500;
}
.baseInfoTable {
    background: #fff;
    padding: 30px;
    margin-top: 20px;
}
.baseTabHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.baseTabHead h5 {
    font-size: 16px;
    color: #141414;
    font-weight: 600;
    margin: 0;
}
.TabLeftFilter {
    display: flex;
    align-items: center;
}
.TabLeftFilter .formGroup {
    position: relative;
    margin-right: 20px;
}
.TabLeftFilter .formGroup span {
    position: absolute;
    top: 8px;
    left: 18px;
}
.TabLeftFilter button {
    border: 1px solid #EBF2FF;
    background: #fff;
    font-size: 14px;
    font-weight: 500;
    border-radius: 100px;
    padding: 12px 19px;
}
.TabLeftFilter button img {
  margin-right: 4px;
}
.formGroup input {
    height: 44px;
    background: #00000008;
    border: 0;
    border-radius: 100px;
    padding: 0 34px;
    font-size: 14px;
    min-width: 400px;
    padding-left: 45px;
}
.baseInfoTable tr th {
    background: #F6F7F9;
    border: 1px solid #EBF2FF;
    font-size: 12px;
    color: #989CA0;
    padding: 13px 18px;
}
.baseInfoTable tr td {
    border: 1px solid #EBF2FF;
    font-size: 12px;
    color: #141414;
    padding: 13px 18px;
}
.baseInfoTable table{
    width: 100%;
}
span.green {
    border: 1px solid #6ADB6A;
    color: #6ADB6A;
    padding: 6px 12px;
    border-radius: 100px;
    font-weight: 600;
    background: #F4F3FB;
}
span.red {
    border: 1px solid #FB3B52;
    color: #FB3B52;
    padding: 6px 12px;
    border-radius: 100px;
    font-weight: 600;
    background: #FFF0F2;
}
span.yellow {
    border: 1px solid #FFC053;
    color: #FFC053;
    padding: 6px 12px;
    border-radius: 100px;
    font-weight: 600;
    background: #FFF9F0;
}
.timeSheetdetils {
    border: 1px solid #00000045;
    border-radius: 11px;
    margin-top: 30px;
    padding: 27px;
}
.timeSheetdetilshead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;
    flex-wrap: wrap;
}
.timeSheetdetilshead button{
    font-size: 12px;
}
.timeSheetdetilshead h4 {
    font-size: 20px;
    color: #141414;
}
.timeSheetdetilshead h4 img {
    margin-left: 4px;
}
.editBtns {
    display: flex;
    align-items: center;
}
.editBtns button {
    border: 1px solid #EBF2FF;
    background: #fff;
    font-size: 15px;
    border-radius: 100px;
    padding: 8px 17px;
    margin-left: 10px;
    color: #141414;
    font-weight: 500;
}
.editBtns button img {
    margin-right: 2px;
    position: relative;
    top: -1px;
}
.innerbrd {
    border-left: 1px solid #ddd;
    padding: 10px 14px;
    font-size: 15px;
    min-width: 26%;
    min-height: 43px;
}
.topBrd {
    align-items: center;
    width: 100%;
    display: flex;
    border-top: 1px solid #ddd;
}
.personalDetials {
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    margin-bottom: 33px;
}
.topBrd.Morefieldss .innerbrd {
    min-width: 18%;
}
.topBrd.brdColor .innerbrd
{
    min-width: 18%;
    background: #F8FAFE;
}
.topBrd.brdColor
{  background: #F8FAFE;
}
.billingDeailLeft ul {
    padding: 0;
    margin: 0;
}
.billingDeailLeft ul li {
    list-style: none;
    border: 1px solid #ddd;
    padding: 0px 20px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0;
}
.billingDeailLeft ul li:last-child {
    border-bottom: 1px solid #ddd;
}
.billingDeailLeft ul li label {
    padding: 9px 0;
    border-left: 1px solid #ddd;
    min-width: 120px;
    text-align: right;
}
.addComment {
    margin-top: 24px;
}
.addComment h4 {
    font-size: 20px;
    color: #141414;
    font-weight: 600;
    margin-bottom: 11px;
}
.addComment textarea {
    width: 100%;
    border: 1px solid #00000045;
    border-radius: 10px;
    padding: 11px 14px;
    height: 100px;
}
.commentBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 14px 0 10px;
}
.commentBtn button {
    background: #0053CD;
    border-radius: 10px;
    font-size: 16px;
    color: #fff;
    border: 0;
    width: 100%;
    height: 50px;
    margin: 0 7px;
}
.commentBtn button.reject {
    background: #FF0000;
}
@media (max-width:1500px)
{
  .rightContainer {
    width: 80%;
    /* padding: 25px 18px; */
}
.leftContainer {
  width: 20%;
}
}
@media (max-width:1199px)
{
  .Tbalelayout table tr th {
    font-size: 14px;
}
.Tbalelayout table tr td {
  padding: 9px 0;
  font-size: 14px;
}
.Tbalelayout table {
  width: 125%;
}
.Tbalelayout {
  overflow-x: auto;
}
.rightContainer h2 {
  font-size: 23px;
  margin: 0 0 16px;
}
}
@media (max-width:991px)
{
  .leftContainer {
    width: 50%;
    position: absolute;
    display: none;
}
.rightContainer {
  width: 100%;
  /* padding: 25px 14px; */
}
}
@media (max-width:767px)
{
  .zOuterTime {
    padding-bottom: 13px;
}
.rightContainer h2 {
  font-size: 20px;
  margin: 0 0 10px;
}
.tableTime {
  padding: 15px;
}
.Tabstable {
  padding: 14px 0 14px;
}
.Tabstable ul li a {
  font-size: 13px;
}
.Tabstable ul li {
  margin-right: 20px;
}
.Tbalelayout table tr th {
  padding: 9px 0;
  font-size: 12px;
}
.Tbalelayout table tr td {
  padding: 9px 10px 9px 0;
  font-size: 12px;
}
.Tbalelayout table {
  width: 258%;
}
.leftContainer {
  width: 80%;
}
}

/**/

.tabletimesheet table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

.tabletimesheet th span, .tabletimesheet td span{
    background-color: transparent;
    border: 0;
}
.tabletimesheet th, .tabletimesheet td {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
    min-width: 150px;
    font-size: 14px;
    font-family: "Poppins", serif !important;

}
