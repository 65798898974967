.pageContainer {
    display: flex;
    flex-wrap: wrap;
    background: #F8FAFE;
}
.leftContainer {
    width: 16%;
    height: calc(100vh - 72px);
    overflow: hidden;
}
.rightContainer {
    width: 84%;
    padding: 20px 15px;
    height: calc(100vh - 80px);
    overflow-x: hidden;
    overflow-y: auto;
}
.pageOuter
{
    padding: auto;
    height: 100vh;
    overflow: hidden;
}
.graphPage img {
    width: 100%;
}
.graphPage {
    margin-bottom: 27px;
}
.timeShetet {
    background: #0053CD;
    border-radius: 10px;
    padding: 28px;
    display: flex;
    align-items: center;
}
.timeShetet span {
    border: 1px solid #FFFFFF;
    width: 80px;
    height: 80px;
    border-radius: 10px;
    margin-right: 14px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.rightTime h6 {
    font-size: 19px;
    color: #fff;
    margin-bottom: 13px;
}
.rightTime h5 {
    font-size: 48px;
    color: #fff;
    font-weight: 800;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.rightTime h5 label {
    font-size: 13px;
}
.rightTime {
    width: 62%;
}
.secTimesheet {
    background: #48A9F8;
}
.thirdTimesheet{
    background: #1BD084;
}
.rightContainer h2 {
    font-size: 30px;
    font-weight: 600;
    margin: 0 0 23px;
}
.OuterTime
{
    padding-bottom: 25px;
}
.revenueBox {
    background: #fff;
    border: 1px solid #EFEFEF;
    border-radius: 10px;
    padding: 15px;
}
.revenueBoxHead {
    display: flex;
    justify-content: space-between;
    min-height: 50px;
    align-items: center;
}
.revenueBoxHead h6 {
    margin: 0 0 20px 0;
    font-size: 14px;
    color: #141414;
}
.revenueBoxHead span {
    font-size: 12px;
    color: #1DBF73;
    border: 1px solid;
    padding: 4px 8px;
    border-radius: 22px;
    background: #8fe7be1f;
}
.revenueBoxFoter h4 span {
    color: #989CA0;
}
.revenueBoxFoter span {
    color: #0053CD;
    font-weight: 600;
}
.revenueBoxFoter h4 {
    font-size: 13px;
    margin: 0;
}
.revenueBoxFoter {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.revenueBox h5 {
    font-size: 26px;
    font-weight: 700;
}
.bussinesButton {
    background: #0053CD;
    padding: 41px 40px;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.bussinesButton button {
    background: #fff;
    border: 0;
    margin-left: 10px;
    padding: 9px 12px;
    font-size: 14px;
    border-radius: 5px;
    color: #21242A;
}
.ProfileTabouter {
    background: #fff;
    border-radius: 12px;
    margin-bottom: 29px;
}
.profileViewLeft {
    display: flex;
    align-items: flex-end;
    margin-top: -70px;
    padding: 0 44px 30px;
}
.profileViewData {
    padding-left: 22px;
}
.profileViewData h3 {
    font-size: 20px;
    margin: 0 0 5px;
    color: #3D475C;
    display: flex;
    align-items: center;
}
.profileViewData h3 span {
    font-size: 12px;
    background: #0053CD;
    color: #fff;
    padding: 8px 12px;
    border-radius: 100px;
    margin-left: 13px;
}
.profileViewData p {
    font-size: 13px;
    margin: 0;
    color: #9499A1;
}
.profileViewData ul {
    margin: 10px 0 0;
    padding: 0;
    display: flex;
}
.profileViewData ul li {
    list-style: none;
    margin-right: 32px;
    color: #9499A1;
}
.ProfileLonks {
    border-top: 1px solid #DEE2E6;
    padding: 21px 44px;
}
.ProfileLonks ul {
    padding: 0;
    margin: 0;
    display: flex;
}
.ProfileLonks ul li {
    margin-right: 30px;
    list-style: none;
}
.ProfileLonks ul li a {
    text-decoration: none;
    color: #9499A1;
    font-size: 15px;
    position: relative;
}
.ProfileLonks ul li.active a {
    color: #0e53cd;
    font-weight: 600;
}
.ProfileLonks ul li.active a::before {
    content: "";
    position: absolute;
    bottom: -23px;
    left: 0;
    right: 0;
    width: 100%;
    height: 3px;
    border-radius: 10px;
    background: #0e53cd;
}
.ProfileTime ul {
    padding: 0;
    margin: 0;
}
.ProfileTime ul li {
    list-style: none;
    font-size: 15px;
    color: #fff;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 0;
}
.ProfileTime.OuterTime h6 {
    margin-bottom: 2px;
}
.ProfileTime ul li.Due {
    color: #FF424D;
}
.ProfileTime ul li.Pending {
    color: #FFC053;
}
.ProfileTime ul li.Approved {
    color: #2BC155;
}
.ProfileTime.OuterTime .timeShetet
{
    align-items: flex-start;
}
.ProfileTime.OuterTime .thirdTimesheet.timeShetet
{
    background: #7E44FB;
}
.profilePointsinner {
    background: #FFFFFF;
    box-shadow: 0 0 10px 1px #dddddd6e;
    border-radius: 10px;
    padding: 25px;
}
.profilePointsrow {
    display: flex;
    margin: 0 -15px;
}
.profilePointscol {
    padding: 0 15px;
    width: 25%;
}
.profilePointsinner h6 {
    font-size: 16px;
    color: #414141;
    font-weight: 400;
}
.profilePointsinner h5 {
    font-size: 28px;
    font-weight: 700;
    margin: 17px 0 50px;
}
.eyeCtach {
    display: flex;
    align-items: center;
}
.eyeCtach span {
    margin-right: 15px;
}
.catchText h4 {
    font-size: 17px;
    color: #2BC155;
    margin: 0;
}
.catchText p {
    font-size: 13px;
    color: #B0B0B0;
    margin: 0;
}
.profilePointscolSec .catchText h4
{
color: #0053CD;
}
.profilePoints
{
    margin-bottom: 30px;
}
.ongoingProject h4 {
    color: #141414;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 20px;
}
.topHeaderongoing {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 18px;
}
.leftshowcase h6 {
    font-size: 16px;
    color: #000000;
    margin: 0;
}
.leftshowcase h5 {
    font-size: 14px;
    color: #8A8A8A;
    margin: 3px 0 0;
}
.rightshowcase span {
    font-size: 16px;
    color: #5C5C5C;
    font-weight: 600;
    margin-left: 30px;
    display: flex;
    align-items: center;
}
.rightshowcase .FormGroup {
    margin-left: 30px;
    position: relative;
    border: 1px solid #B1CBFF;
    border-radius: 100px;
    padding: 9px 21px;
    display: flex;
    padding-left: 51px;
}
.rightshowcase span img {
    margin-right: 7px;
}
.rightshowcase .FormGroup span {
    position: absolute;
    left: 15px;
    margin: 0;
    top: 11px;
}
.rightshowcase .FormGroup select {
    border: 0;
    background: transparent;
    font-size: 16px;
    color: #5C5C5C;
    font-weight: 600;
    padding-right: 8px;
}
.prjectCards {
    background: #fff;
    padding: 29px;
    border-radius: 10px;
    margin-bottom: 22px;
    box-shadow: 0px 4px 2px 0px #ddddddc7;
}
.projectCadtop {
    display: flex;
    justify-content: space-between;
}
.projectCadleft h6 {
    font-size: 16px;
    color: #797979;
}
.projectCadleft h5 {
    font-size: 20px;
    color: #000000;
}
.projectCadleft h4 {
    color: #40189D;
    font-size: 16px;
    margin: 0;
}
.projectCadleft ul {
    padding-left: 20px;
    margin: 14px 0 0;
}
.projectCadleft ul li {
    font-size: 15px;
}
.projectCadright span {
    font-size: 16px;
    width: 100%;
    display: table;
    color: #2E2E2E;
    font-weight: 500;
    text-align: right;
}
.projectCadright img {
    margin-left: auto;
    float: right;
    margin-bottom: 10px;
    max-width: 80px;
    border-radius: 10px;
    max-height: 80px;
    object-fit: cover;
}
.prjectCardsBtn button {
    background: #0053CD1A;
    border: 0;
    border-radius: 100px;
    padding: 11px 23px;
    color: #0053CD;
    font-weight: 500;
}
.prjectCardsBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}
.rightshowcase
{
    display: flex;
    align-items: center;
}
@media (max-width:1500px)
{
  .rightContainer {
    width: 80%;
    /* padding: 25px 18px; */
}
.leftContainer {
  width: 20%;
}
}
@media (max-width:1199px)
{
  .Tbalelayout table tr th {
    font-size: 14px;
}
.Tbalelayout table tr td {
  padding: 9px 0;
  font-size: 14px;
}
.Tbalelayout table {
  width: 125%;
}
.Tbalelayout {
  overflow-x: auto;
}
.rightContainer h2 {
  font-size: 23px;
  margin: 0 0 16px;
}
}
@media (max-width:991px)
{
  .leftContainer {
    width: 50%;
    position: absolute;
    display: none;
}
.rightContainer {
  width: 100%;
  /* padding: 25px 14px; */
}
}
@media (max-width:767px)
{
  .zOuterTime {
    padding-bottom: 13px;
}
.rightContainer h2 {
  font-size: 20px;
  margin: 0 0 10px;
}
.tableTime {
  padding: 15px;
}
.Tabstable {
  padding: 14px 0 14px;
}
.Tabstable ul li a {
  font-size: 13px;
}
.Tabstable ul li {
  margin-right: 20px;
}
.Tbalelayout table tr th {
  padding: 9px 0;
  font-size: 12px;
}
.Tbalelayout table tr td {
  padding: 9px 10px 9px 0;
  font-size: 12px;
}
.Tbalelayout table {
  width: 258%;
}
.leftContainer {
  width: 80%;
}
}