.sidebar {
    background: #FFFFFF;
    border-right: 1px solid #E4E5E8;
    padding: 24px 23px;
    height: 100%;
    position: sticky;
    top: 0;
    padding-right: 0;
    overflow: auto;
}
.SearchSide {
    position: relative;
}
.SearchSide input {
    background: #F8FAFE;
    border: 0;
    height: 44px;
    padding: 0 19px;
    border-radius: 100px;
    padding-left: 50px;
    width: 100%;
}
.SearchSide span {
    position: absolute;
    left: 22px;
    top: 7px;
}
.sidebar h6 {
    color: #989CA0;
    font-size: 12px;
    font-weight: 600;
    margin: 22px 0 12px;
}
.sidebar ul {
    padding: 0;
    margin: 0;
}
.sidebar ul li {
    list-style: none;
    position: relative;
}
.sidebar ul li a {
    padding: 10px 17px;
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 40px;
    font-size: 14px;
    text-decoration: none;
    font-weight: 500;
    color: #989CA0;
   
}
.sidebar ul li a img{
    filter: grayscale(100);
}
.sidebar ul li.active a {
    background-image: linear-gradient(to right, #0053CD1A , #0053cd00);
    color: #0053CD;
    font-weight: 600;
   
}
.sidebar ul li.active a img {
    filter: none;
}
.sidebar ul li.active::before
{
    position: absolute;
    right: 0;
    top: 7px;
    background: #0053CD;
    width: 4px;
    height: 30px;
    content: "";
}
.sidebar ul li a img {
    margin-right: 8px;
}
@media (max-width:1199px)
{
    .sidebar {
        padding: 14px 14px;
        padding-right: 0;
    }
    .sidebar ul li a {
        padding: 10px 12px;
        font-size: 13px;
    }
}