body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/**/
.clamp{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.clamp1{
  -webkit-line-clamp: 1;
}
.clamp2{
  -webkit-line-clamp: 2;
}
.clamp3{
  -webkit-line-clamp: 3;
}
label{
  font-size: 14px !important;
}
.form-control{
  height: 48px;
  font-size: 14px !important;
  text-transform: capitalize;
}

:focus{
  outline: none !important;
  box-shadow: none !important;
}
body .btn-primary{
  font-size: 14px;
  background-color: #0053cd;
  border-color: #0053cd;
}

.dropdown-item{
  font-size: 14px !important;
}
.dropdowns.show{
  padding-top: 0;
  padding-bottom: 0;
}
.dropdown-item.active, .dropdown-item:active{
  background-color: #587dff;
}
.modal-title, .h4.modal-title{
  font-size: 20px;
}

.list-style-none{
  list-style: none;
}
.proSlider .slick-list > .slick-track {
  display: flex;
  gap: 22px;
}
.lightblue{
  background-color: #587dff;
}

.proSlider{
  position: relative;
}

.proSlider .slick-dots{
display: flex !important;
align-items: center;
justify-content: center;
list-style: none;
}
.proSlider .slick-dots li.slick-active button{
  background: #0053cd;
}
.proSlider .slick-dots li button{
    border: 0; 
    font-size: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.text-14{
  font-size: 14px;
}