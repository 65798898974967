.viewProiflePage {
  /* background: #F7F7F7;
    padding: 40px 0; */
}

.viewProfileLeft h3 {
  margin-bottom: 16px;
  font-size: 20px;
  color: #141414;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profileHead {
  border: 1px solid #D7D8E2;
  border-radius: 13px;
  background: #fff;
}

.profileCover img {
  width: 100%;
}

.profileCover {
  position: relative;
}

.endroProfile span img {
  max-width: 55px;
  border-radius: 100px;
  margin-right: 12px;
}

.endroProfile div h5 {
  font-size: 18px;
  margin: 0;
  color: #000000;
}

.endroProfile div h6 {
  font-size: 15px;
  color: #000000;
  margin: 4px 0 0;
}

.endroProfile div span {
  font-size: 14px;
  margin: 0;
  color: #000;
  opacity: 0.5;
}

.endroProfile {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
}

.ebdrotabs ul li.active span {
  color: #0053CD;
  font-weight: 500;
  border-bottom: 2px solid;
  border-radius: 0;
}

.ebdrotabs ul {
  margin: 30px 0 10px !important;
}

.ebdrotabs ul li span {
  background: transparent !important;
  padding: 0 !important;
  font-size: 16px;
  color: #8d8d8d;
  margin-right: 35px;
}

.profileCover span img {
  width: auto;
}

.profileCover span {
  position: absolute;
  top: 15px;
  right: 15px;
}

.profileName {
  padding: 0px 27px 25px;
}

.prfoleHead button {

  background: #0053CD;
  border: 0;
  border-radius: 100px;
  margin-left: 7px;
  padding: 6px 19px;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  border: 1px solid #0053CD;

}

.prfoleHead div {
  display: flex;
  align-items: center;
}

.prfoleHead div img {
  margin: 0;
  margin-left: 10px;
}

.prfoleHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.prfoleHead img {
  max-width: 157px;
  border-radius: 100px;
  margin-top: 30px;
  position: relative;
}

.prfoleHead label {
  font-weight: 500;
  color: #000;
}

.profileName h4 {
  font-size: 18px;
  font-weight: 500;
  margin: 20px 0 9px;
}

.connectBtns button {
  background: #0e53cd;
  border: 0;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 20px;
}

.connectBtns i {
  background: #e2ecfb;
  padding: 10px 15px;
  border-radius: 5px;
}

.connectBtns span {
  background: #fff;
  border: 0;
  border-radius: 5px;
  color: #0e53cd;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 20px;
  border: 1px solid #0e53cd;
  margin: 0 15px;
}

.profileName h4 span {
  font-size: 14px;
}

.profileName span img {
  margin-right: 6px;
  position: relative;
  top: -2px;
}

.location span {
  width: 100%;
  display: table;
  margin: 0 0 8px;
}

.location {
  margin: 0 0 15px;
}

.PayRate h5 {
  font-size: 14px;
  font-weight: 600;
}

.PayRate ul {
  padding: 0;
  display: flex;
}

.PayRate ul li {
  width: 24.33%;
}

.PayRate ul li label {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
}

.PayRate ul li span {
  color: #0053CD;
  font-size: 18px;
  font-weight: 500;
}

.BostProfile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.BostProfile button {
  background: #E88715;
  color: #fff;
  border: 0;
  font-weight: 600;
  padding: 13px 18px;
  border-radius: 7px;
}

.BostProfile ul {
  display: flex;
  align-items: center;
  color: #9499A1;
}

.BostProfile ul li {
  margin-right: 13px;
}

.BostProfile li a {
  border: 1px solid #D4D4D4;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.reviewSection {
  border: 1px solid #D7D8E2;
  border-radius: 13px;
  background: #fff;
  margin-top: 28px;
  padding: 30px 0;
}

.reviewSection h3 {
  padding: 0 27px 17px;
}

.revieStar img {
  max-width: 30px;
  border-radius: 100px;
  margin-right: 11px;
}

.revieStar h4 {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
}

.revieStar div img {
  max-width: 100%;
}

.reviwSection p {
  color: #00000080;
  font-size: 12px;
  margin: 7px 0 0;
}

.textReview {
  padding: 0 27px 26px;
  display: flex;
  align-items: center;
}

.textReview span img {
  max-width: 42px;
  border-radius: 100px;
  margin-right: 13px;
}

.textReview .formGroup {
  width: 100%;
  position: relative;
}

.textReview .formGroup input {
  height: 42px;
  background: #F7F7F7;
  border: 0;
  border-radius: 5px;
  padding: 0 14px;
  width: 100%;
}

.textReview .formGroup label {
  position: absolute;
  right: 13px;
  top: 9px;
}

.revieStar .reply {
  margin-left: auto;
}

.revieStar .reply a {
  font-size: 12px;
  text-decoration: none;
  font-weight: 400;
}

.revieStar .reply a img {
  margin: 0 2px 0;
}

.reviwSection {
  padding: 0 26px 19px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 25px;
}

.reviwSection.noborder {
  border: 0;
  margin-bottom: 10px;
}

.revieStar {
  display: flex;
}

.loadMore a {
  text-align: center;
  width: 100%;
  display: table;
  color: #0053CD;
  font-weight: 500;
  text-decoration: none;
}

.personalHead {
  border: 1px solid #D7D8E2;
  border-radius: 13px;
  background: #fff;
  margin-top: 21px;
  overflow: hidden;
  padding-bottom: 14px;
}

.editBns span {
  margin-left: 10px;
}

ul.secureFiles {
  padding: 0 20px;
}

ul.secureFiles li .ouetDoc {
  display: flex;
  align-items: center;
  background: #F7F7F7;
  width: 100%;
  padding: 8px 10px;
  border-radius: 5px;
}

ul.secureFiles li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0;
}

ul.secureFiles li button {
  background: #0053CD33;
  border: 0;
  border-radius: 4px;
  padding: 7px 9px;
  margin-left: 13px;
}

ul.secureFiles li .ouetDoc img {
  margin-right: 7px;
}

ul.bankInformation,
ul.Emergency,
ul.LanguageFlag {
  padding: 14px 20px 5px;
  margin: 0;
}

ul.bankInformation li,
ul.Emergency li,
ul.LanguageFlag li {
  padding: 4px 0;
  font-size: 15px;
}

ul.LanguageFlag li img {
  margin-right: 5px;
}

.personalName {
  display: flex;
  align-items: center;
  background: #f7f7f7;
  border-bottom: 1px solid #00000014;
  padding: 9px 15px;
  justify-content: space-between;
}

.personalName h4 {
  color: #141414;
  font-size: 18px;
  margin: 0;
  font-weight: 500;
}

.personalHead p {
  color: #000000;
  font-size: 16px;
  padding: 20px 20px;
  margin: 0;
}

.personlDetal {
  padding: 0 20px 13px;
}

.personlDetal span {
  width: 100%;
  display: table;
}

.personlDetal label {
  font-size: 19px;
}




.skillset {
  border: 1px solid #D7D8E2;
  border-radius: 13px;
  background: #fff;
  margin-top: 28px;
  padding: 25px;
}

.skilhead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 17px;
}

.skilhead h3 {
  font-size: 20px;
  font-weight: 600;
  color: #141414;
}

.skilladd span {
  margin-left: 11px;
}

.skillset h4 {
  font-size: 16px;
  color: #000000;
  font-weight: 500;
  margin: 15px 0 13px;
}

.skillset p {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
}

.skillset ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.skillset ul li {
  /* margin-right: 13px; */
}

.skillset ul li span {
  background: #F5F7FD;
  display: inline-block;
  border-radius: 100px;
  padding: 10px 37px;
  margin-bottom: 13px;
}

.experset {
  display: flex;
}

.experset h4 {
  margin: 0 0 10px;
  font-size: 16px;
  font-weight: 500;
}

.experset h5 {
  font-size: 14px;
  font-weight: 400;
}

.experset p {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.experset span {
  margin-right: 19px;
}

.experset {
  margin-bottom: 30px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 30px;
}

.compDetails {
  width: 95%;
}

.compDeteails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 0 10px;
}

.compDeteails .BostProfile {
  width: 100%;
  justify-content: flex-end;
}

.experset .compDeteails {
  width: 100%;
}

.skillset .experset:last-child {
  border: 0;
  margin: 0;
  padding: 0;
}

.skilhead h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.accountPay ul {
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 32px 0 0;
  padding: 0;
}

.accountPay ul li {
  padding: 23px;
  width: 25%;
  margin: 0;
  border-right: 1px solid #ddd;
}

.accountPay ul li h4 {
  font-size: 14px;
  margin: 0 0 12px;
  font-weight: 600;
  max-width: 90%;
}

.accountPay ul li p {
  font-size: 14px;
  margin: 0;
  font-weight: 400;
}

.RateSatr h5 {
  font-size: 16px;
  font-weight: 500;
}

.RateSatr h5 img {
  margin-right: 4px;
}

.proslideOut {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 18px 18px 44px;
  margin: 0 0;
  position: relative;
}

.proslideOutHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leftProHead span {
  font-size: 12px;
  font-weight: 600;
  margin: 0;
}

.proslideOut .leftProHead h4 {
  margin: 6px 0 23px;
}

.proslideOut p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.proslideOut a {
  background: #0053CD;
  width: 100%;
  position: absolute;
  bottom: 0;
  text-align: center;
  color: #fff;
  text-decoration: none;
  font-weight: 400;
  font-size: 15px;
  padding: 8px 0;
  border-radius: 0 0 5px 5px;
  left: 0;
}

.InterviewQues ul li {
  width: 47%;
  font-size: 14px;
}

.InterviewQues ul li span {
  font-size: 14px;
  font-weight: 700;
  padding: 0;
  background: transparent;
  width: 100%;
}

.rightContainer h2 {
  font-size: 30px;
  font-weight: 600;
  margin: 0 0 23px;
}

.tableTime {
  background: #fff;
  padding: 30px;
  border-radius: 13px;
}

.topTime {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topTimeInner {
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.topTime .exportButton {
  margin-left: 4px;
  height: 44px;
}

.topTime .selectButton {
  margin-left: 4px;
  height: 44px;
}

.inline {
  display: inline;
  margin-left: 5px;
}

.dropdownIcon::after {
  display: none;
}

.topTime .selectButton span {
  margin-left: 4px;
}

.topTime h5 {
  font-size: 17px;
  font-weight: 600;
}

.topTime h5 i {
  color: #bebebe;
  font-size: 14px;
  margin-left: 3px;
}

.topTime button {
  font-size: 14px;
  color: #fff;
  border: 0;
  background: #0053cd;
  height: 44px;
  padding: 7px 15px 8px;
  border-radius: 6px;
}

.topTime button img {
  margin-right: 4px;
}

.Tabstable {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0 14px;
}

.Tabstable ul {
  padding: 0;
  margin: 0;
  display: flex;
}

.Tabstable ul li {
  list-style: none;
  margin-right: 20px;
}

.Tabstable ul li a {
  font-size: 15px;
  text-decoration: none;
  color: #000000;
  font-weight: 500;
}

.Tabstable ul li.active a {
  color: #2880da;
  font-weight: 600;
  border-bottom: 2px solid;
  padding-bottom: 3px;
}

.filters {
  display: flex;
  align-items: center;
}

.filters .Formgroup {
  margin-left: 30px;
}

.filters .Formgroup label {
  font-size: 15px;
  color: #96a5b8;
}

.filters .Formgroup select {
  font-size: 15px;
  border: 0;
  outline: none;
  color: #222b45;
  padding: 0 5px 0 2px;
}

.Tbalelayout table {
  width: 100%;
  margin-top: 6px;
}

.Tbalelayout table tr td img {
  margin-right: 7px;
}

.Tbalelayout table tr th {
  color: #989ca0;
  font-weight: 500;
  border-bottom: 1px solid #edf2f6;
  padding: 12px 0;
  font-size: 15px;
  min-width: 60px;
}

.Tbalelayout table tr td {
  border-bottom: 1px solid #edf2f6;
  padding: 12px 0;
  font-size: 15px;
  min-width: 60px;
}

.Tbalelayout table tr td label {
  padding: 5px 10px;
  font-size: 12px !important;
  font-weight: 600;
  background: #2bc15610;
  border: 1px solid #2bc155;
  border-radius: 20px;
  color: #2bc155;
}

/* .Tbalelayout table tr td label.Pending {
    border: 1px solid #ffc053;
    border-radius: 100px;
    color: #ffc053;
  } */
.Tbalelayout table tr td label.inactive {
  border: 1px solid #4f4b46;
  border-radius: 100px;
  color: #4f4b46;
  background: rgba(0, 0, 0, 0.25);
}

.OuterTime {
  padding-bottom: 25px;
}

.revenueBox {
  background: #fff;
  border: 1px solid #efefef;
  border-radius: 10px;
  padding: 15px;
}

.revenueBoxHead {
  display: flex;
  justify-content: space-between;
  min-height: 50px;
  align-items: center;
}

.revenueBoxHead h6 {
  margin: 0 0 20px 0;
  font-size: 14px;
  color: #141414;
}

.revenueBoxHead span {
  font-size: 12px;
  color: #1dbf73;
  border: 1px solid;
  padding: 4px 8px;
  border-radius: 22px;
  background: #8fe7be1f;
}

.revenueBoxFoter h4 span {
  color: #989ca0;
}

.revenueBoxFoter span {
  color: #0053cd;
  font-weight: 600;
}

.revenueBoxFoter h4 {
  font-size: 13px;
  margin: 0;
}

.revenueBoxFoter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.revenueBox h5 {
  font-size: 26px;
  font-weight: 700;
}

.add-lead-from h4 {
  color: #000;
  text-align: center;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 14px 0 14px;
}

.add-lead-from form .rupeefield input {
  width: 90% !important;
  display: inline !important;
}

.add-lead-from p {
  color: #000;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  opacity: 0.6;
}

.spacebetween {
  display: flex;
  width: 100% !important;
  justify-content: space-between;
}

.add-lead-from form input {
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  background: var(--background-mode, #fff);
  width: 100%;
  height: 43px;
  padding: 0 18px;
  font-size: 14px;
}

.add-lead-from form select {
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  background: var(--background-mode, #fff);
  width: 100%;
  height: 43px;
  padding: 0 18px;
  font-size: 14px;
  color: #717171;
}

.add-more {
  border-radius: 10px;
  border: 1px dashed #094e85;
  background: var(--background-mode, #fff);
  text-align: center;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #094e85;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.06px;
}

.add-lead-from {
  /* padding: 0 15px; */
}

.add-lead-from-page {
  padding: 0 15px;
  width: 40%;
  margin: auto;
}

.add-lead-from h1 {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.add-lead-from form .from-group input[type="submit"] {
  background: #094e85;
  color: #fff;
  font-size: 17px;
}

.fieldStyle {
  margin-bottom: 10px;
}

.add-lead-from input[type="radio"] {
  width: 12px;
  height: 11px;
  padding: 0;
  margin-top: 7px;
  margin-bottom: 20px;
}

.add-lead-from input[type="checkbox"] {
  width: 12px;
  height: 11px;
  padding: 0;
  margin-top: 7px;
  margin-bottom: 20px;
}

.buttonss {
  width: 100% !important;
  --bs-btn-hover-bg: #0b5ed7;
}

.statusbuttons {
  width: 100px;
}

.add-lead-from input::-webkit-outer-spin-button,
.add-lead-from input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.add-lead-from input[type="number"] {
  -moz-appearance: textfield;
}

.error {
  color: red;
  font-size: 13px;
  margin-bottom: 0px;
}

.dropdownIcon::after {
  display: none;
}

.pp {
  width: 30px;
  margin-right: 6px;
}

.spinner {
  margin-top: 10px;
  /* width: 98vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row; */

}

.active .leftContainer li a {
  font-size: 0;
}

.capitalize {
  text-transform: capitalize;
}

.formGroup input {
  background: #F8FAFE;
  border: 0;
  height: 44px;
  padding: 0 19px;
  border-radius: 100px;
  min-width: 200px;
  padding-left: 50px;
}

.formGroup {
  position: relative;
  margin-left: 30px;
}

/* .formGroup button span {
    position: absolute;
    left: 22px;
    top: 7px;
  } */

.formGroup button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.resetButton {
  margin-left: 10px;
  color: #9DA1A5 !important;
  border: solid #9DA1A5 1px !important;
  padding: 8px 15px !important;
  border-radius: 18px !important;
  font-size: 10px;
}

button.SaerchTag {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.FilterBtn {
  display: flex;
}

.FilterBtn div {
  margin-right: 13px;
}

.extrafilter {
  margin-top: 30px;
}



@media (max-width:1199px) {
  .Tbalelayout table tr th {
    font-size: 14px;
  }

  .Tbalelayout table tr td {
    padding: 9px 0;
    font-size: 14px;
  }

  .Tbalelayout table {
    width: 150%;
  }

  .Tbalelayout {
    overflow-x: auto;
  }

  .rightContainer h2 {
    font-size: 23px;
    margin: 0 0 16px;
  }
}

@media (max-width:991px) {
  .active .leftContainer li a {
    font-size: 14px;
  }

  .leftContainer {
    display: none;
  }
}

@media (max-width:767px) {
  .zOuterTime {
    padding-bottom: 13px;
  }

  .rightContainer h2 {
    font-size: 20px;
    margin: 0 0 10px;
  }

  .tableTime {
    padding: 15px;
  }

  .Tabstable {
    padding: 14px 0 14px;
  }

  .Tabstable ul li a {
    font-size: 13px;
  }

  .Tabstable ul li {
    margin-right: 20px;
  }

  .Tbalelayout table tr th {
    padding: 9px 0;
    font-size: 12px;
  }

  .Tbalelayout table tr td {
    padding: 9px 10px 9px 0;
    font-size: 12px;
  }

  .Tbalelayout table {
    width: 308%;
  }
}