.topBarleft {
    display: flex;
    align-items: center;
}
.topBar {
    border-bottom: 1px solid #E4E5E8;
    padding: 14px 23px;
}
.topBarright {
    display: flex;
    justify-content: flex-end;
}
.topBarleft .formGroup input {
    background: #F8FAFE;
    border: 0;
    height: 44px;
    padding: 0 19px;
    border-radius: 100px;
    min-width: 400px;
    padding-left: 50px;
}
.topBarleft .formGroup {
    position: relative;
    margin-left: 30px;
}
.topBarleft .formGroup span {
    position: absolute;
    left: 22px;
    top: 7px;
}
.userLog {
    display: flex;
    align-items: center;
    margin-left: 37px;
}
.notifyIc span {
    width: 44px;
    height: 44px;
    border: 1px solid #EDEDED;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    margin-left: 13px;
}
.Username h6 {
    font-size: 14px;
    margin: 0;
    color: #141414;
}
.userLog span {
    margin-right: 8px;
}
.userLog span img {
    max-width: 44px;
    max-height: 44px;
    border-radius: 100px;
}
.Username p {
    margin: 0;
    font-size: 12px;
    color: #989CA0;
}
.notifyIc {
    display: flex;
    align-items: center;
}
.topBarleft span {
    margin-left: 25px;
    font-size: 18px;
    cursor: pointer;
}
@media (max-width:767px)
{
    .topBarleft img {
        width: 100%;
    }
    .userLog span img {
        max-width: 38px;
        max-height: 38px;
    }
    .Username h6 {
        font-size: 13px;
    }
    .topBarleft div {
        display: flex;
        align-items: center;
    }
    .topBarleft div span {
        order: 1;
        margin: 0 14px 0 0;
    }
    .topBarleft img {
        width: 100%;
        order: 2;
    }
}