.pageContainer {
    display: flex;
    flex-wrap: wrap;
    background: #F8FAFE;
}
.leftContainer {
    width: 16%;
    height: calc(100vh - 72px);
    overflow: hidden;
}
.rightContainer {
    width: 84%;
    padding: 20px 15px;
    height: calc(100vh - 80px);
    overflow-x: hidden;
    overflow-y: auto;
}
.pageOuter
{
    padding: auto;
    height: 100vh;
    overflow: hidden;
}
.graphPage img {
    width: 100%;
}
.graphPage {
    margin-bottom: 27px;
}
.timeShetet {
    background: #0053CD;
    border-radius: 10px;
    padding: 28px;
    display: flex;
    align-items: center;
}
.timeShetet span {
    border: 1px solid #FFFFFF;
    width: 80px;
    height: 80px;
    border-radius: 10px;
    margin-right: 14px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.rightTime h6 {
    font-size: 19px;
    color: #fff;
    margin-bottom: 13px;
}
.rightTime h5 {
    font-size: 48px;
    color: #fff;
    font-weight: 800;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.rightTime h5 label {
    font-size: 13px;
}
.rightTime {
    width: 62%;
}
.secTimesheet {
    background: #48A9F8;
}
.thirdTimesheet{
    background: #1BD084;
}
.rightContainer h2 {
    font-size: 30px;
    font-weight: 600;
    margin: 0 0 23px;
}
.OuterTime
{
    padding-bottom: 25px;
}
.revenueBox {
    background: #fff;
    border: 1px solid #EFEFEF;
    border-radius: 10px;
    padding: 15px;
}
.revenueBoxHead {
    display: flex;
    justify-content: space-between;
    min-height: auto;
    align-items: center;
}
.revenueBoxHead h6 {
  margin: 0 0 20px 0;
  font-size: 14px;
    color: #141414;
}
.profit {
    font-size: 12px;
    color: #1DBF73;
    border: 1px solid;
    padding: 4px 8px;
    border-radius: 22px;
    background: #8fe7be1f;
}
.loss{
  font-size: 12px;
  color: #e1380e;
  border: 1px solid;
  padding: 4px 8px;
  border-radius: 22px;
  background: #e79e8f1f;
}

.revenueBoxFoter h4 span {
    color: #989CA0;
}
.revenueBoxFoter span {
    color: #0053CD;
    font-weight: 600;
}
.revenueBoxFoter h4 {
    font-size: 13px;
    margin: 0;
}
.revenueBoxFoter {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.revenueBox h5 {
    font-size: 26px;
    font-weight: 700;
}
@media (max-width:1500px)
{
  .rightContainer {
    width: 80%;
    /* padding: 25px 18px; */
}
.leftContainer {
  width: 20%;
}
}
@media (max-width:1199px)
{
  .Tbalelayout table tr th {
    font-size: 14px;
}
.Tbalelayout table tr td {
  padding: 9px 0;
  font-size: 14px;
}
.Tbalelayout table {
  width: 125%;
}
.Tbalelayout {
  overflow-x: auto;
}
.rightContainer h2 {
  font-size: 23px;
  margin: 0 0 16px;
}
}
@media (max-width:991px)
{
  .leftContainer {
    width: 50%;
    position: absolute;
    display: none;
}
.rightContainer {
  width: 100%;
  /* padding: 25px 14px; */
}
}

@media (max-width:767px)
{
  .zOuterTime {
    padding-bottom: 13px;
}
.rightContainer h2 {
  font-size: 20px;
  margin: 0 0 10px;
}
.tableTime {
  padding: 15px;
}
.Tabstable {
  padding: 14px 0 14px;
}
.Tabstable ul li a {
  font-size: 13px;
}
.Tabstable ul li {
  margin-right: 20px;
}
.Tbalelayout table tr th {
  padding: 9px 0;
  font-size: 12px;
}
.Tbalelayout table tr td {
  padding: 9px 10px 9px 0;
  font-size: 12px;
}
.Tbalelayout table {
  width: 258%;
}
.leftContainer {
  width: 80%;
}
}