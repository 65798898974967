

.loginOuter {
    display: flex;
    height: 100%;
    align-items: center;
}
.loginLeft {
    width: 50%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;
    background: #F5F7FD;
}
.logo {
    position: absolute;
    top: 25px;
    left: 30px;
}
.loginRight {
    width: 50%;
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.topLogin {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 500px;
    padding: 40px 0 0;
}
.topLogin p {
    font-size: 14px;
    color: #0E0E0E;
    margin: 0;
}
.topLogin p a {
    color: #0053CD;
    text-decoration: none;
    font-weight: 600;
}
.topLogin button {
    border: 1px solid #0053CD;
    border-radius: 100px;
    background: #fff;
    color: #0053CD;
    font-size: 14px;
    font-weight: 600;
    padding: 11px 17px;
    position: absolute;
    right: -150px;
}
.loginRight form {
    width: 100%;
    max-width: 500px;
    margin: auto;
}
.loginRight form h4 {
    font-size: 32px;
    margin: 0;
    font-weight: 600;

}
p.error-msg {
    margin-bottom: 0 !important;
    color: red !important;
}
.loginRight form p {
    font-size: 14px;
    color: #000;
    font-weight: 400;
    margin-bottom: 20px;
    padding-top: 10px;
}
.loginRight .formGroup label {
    font-size: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000000;
    margin-bottom: 010px;
}
.loginRight .formGroup input {
    height: 48px;
    width: 100%;
    background: #F7F7F7;
    border-radius: 10px;
    border: 0;
    padding: 0 17px;
    font-size: 14px;
}
.loginRight .formGroup {
    margin-bottom: 25px;
    position: relative;
}
.loginRight .formGroup span {
    position: absolute;
    right: 10px;
    bottom: 10px;
}
.loginRight .formGroup label a {
    font-size: 13px;
    text-decoration: none;
    color: #0053CD;
}
.loginRight .formGroup input[type="submit"] {
    background: #0053CD;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    margin-top: 20px;
}
.Or span {
    font-size: 12px;
    color: #6E6E6E;
    letter-spacing: 2px;
    text-transform: uppercase;
    background: #fff;
    margin: 0 auto;
    display: table;
    padding: 0 18px;
    position: relative;
    z-index: 1;
}
.Or hr {
    background: #e7e7e7;
    border: 0;
    height: 1px;
    margin: -7px 0;
}
.socialLogin ul {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.socialLogin ul li {
    list-style: none;
    margin: 0 11px;
}
.Or {
    margin: 40px 0 49px;
}
@media (max-width:991px)
{
    .loginOuter {
        flex-wrap: wrap;
    }
    .loginLeft {
        width: 100%;
        height: auto;
        padding: 60px 0 40px;
    }
    .loginRight {
        width: 100%;
        height: auto;
        padding: 22px 0;
    }
    .loginRight form h4 {
        font-size: 26px;
        margin: 0 0 11px;
    }
    .loginRight form p {
        margin-bottom: 30px;
    }
}
@media (max-width:767px)
{
    .loginLeft img {
        width: 100%;
    }
    .loginLeft{
        padding: 26px 20px 29px;
    }
    .logo {
        position: static;
        margin-bottom: 20px;
    }
    .loginRight {
        padding: 19px 16px;
    }
}
