.rightContainer h2 {
  font-size: 30px;
  font-weight: 600;
  margin: 0 0 23px;
}

.tableTime {
  background: #fff;
  padding: 30px;
  border-radius: 13px;
}
.topTime {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topTimeInner {
  display: flex;
  align-items: end;
  justify-content: space-between;
}
.topTime .exportButton{
  margin-left: 4px;
  height: 44px;
}
.topTime .selectButton
{
  margin-left: 4px;
  height: 44px;
}
.inline{
  display: inline;
  margin-left: 5px;
}
.dropdownIcon::after {
  display: none;
}
.topTime .selectButton span
{
  margin-left: 4px;
}
.topTime h5 {
  font-size: 17px;
  font-weight: 600;
}
.topTime h5 i {
  color: #bebebe;
  font-size: 14px;
  margin-left: 3px;
}
.topTime button {
  font-size: 14px;
  color: #fff;
  border: 0;
  background: #0053cd;
  padding: 7px 15px 8px;
  border-radius: 6px;  height: 44px;
}
.topTime button img {
  margin-right: 4px;
}
.Tabstable {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0 14px;
}
.Tabstable ul {
  padding: 0;
  margin: 0;
  display: flex;
}
.Tabstable ul li {
  list-style: none;
  margin-right: 20px;
}
.Tabstable ul li a {
  font-size: 15px;
  text-decoration: none;
  color: #000000;
  font-weight: 500;
}
.Tabstable ul li.active a {
  color: #2880da;
  font-weight: 600;
  border-bottom: 2px solid;
  padding-bottom: 3px;
}
.filters {
  display: flex;
  align-items: center;
}
.filters .Formgroup {
  margin-left: 30px;
}
.filters .Formgroup label {
  font-size: 15px;
  color: #96a5b8;
}
.filters .Formgroup select {
  font-size: 15px;
  border: 0;
  outline: none;
  color: #222b45;
  padding: 0 5px 0 2px;
}
.Tbalelayout table {
  width: 100%;
  margin-top: 6px;
}
.Tbalelayout table tr td img {
  margin-right: 7px;
}
.Tbalelayout table tr th {
  color: #989ca0;
  font-weight: 500;
  border-bottom: 1px solid #edf2f6;
  padding: 12px 0;
  font-size: 15px;
  min-width: 60px;
}
.Tbalelayout table tr td {
  border-bottom: 1px solid #edf2f6;
  padding: 12px 0;
  font-size: 15px;
  min-width: 60px;
}
.Tbalelayout table tr td label {
  padding: 5px 10px;
  font-size: 12px !important;
  font-weight: 500;
  background: #2bc15610;
  border: 1px solid #2bc155;
  border-radius: 20px;
  color: #2bc155;
}
/* .Tbalelayout table tr td label.Pending {
    border: 1px solid #ffc053;
    border-radius: 100px;
    color: #ffc053;
} */
.Tbalelayout table tr td label.inactive {
  border: 1px solid #4f4b46;
  border-radius: 100px;
  color: #4f4b46;
  background: rgba(0, 0, 0, 0.25);
}
.OuterTime {
  padding-bottom: 25px;
}
.revenueBox {
  background: #fff;
  border: 1px solid #efefef;
  border-radius: 10px;
  padding: 15px;
}
.revenueBoxHead {
  display: flex;
  justify-content: space-between;
  min-height: auto;
  align-items: center;
}
.revenueBoxHead h6 {
  margin: 0 0 20px 0;
  font-size: 14px;
  color: #141414;
  
}
.revenueBoxHead span {
  font-size: 12px;
  color: #1dbf73;
  border: 1px solid;
  padding: 4px 8px;
  border-radius: 22px;
  background: #8fe7be1f;
}
.revenueBoxFoter h4 span {
  color: #989ca0;
}
.revenueBoxFoter span {
  color: #0053cd;
  font-weight: 600;
}
.revenueBoxFoter h4 {
  font-size: 13px;
  margin: 0;
}
.revenueBoxFoter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.revenueBox h5 {
  font-size: 26px;
  font-weight: 700;
}

.add-lead-from h4 {
  color: #000;
  text-align: center;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 14px 0 14px;
}

.add-lead-from form .rupeefield input {
  width: 90% !important;
  display: inline !important;
}
.add-lead-from p {
  color: #000;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  opacity: 0.6;
}
.spacebetween {
  display: flex;
  width: 100% !important;
  justify-content: space-between;
}
.add-lead-from form input {
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  background: var(--background-mode, #fff);
  width: 100%;
  height: 43px;
  padding: 0 18px;
  font-size: 14px;
}

.add-lead-from form select {
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  background: var(--background-mode, #fff);
  width: 100%;
  height: 43px;
  padding: 0 18px;
  font-size: 14px;
  color: #717171;
}

.add-more {
  border-radius: 10px;
  border: 1px dashed #094e85;
  background: var(--background-mode, #fff);
  text-align: center;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #094e85;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.06px;
}

.add-lead-from {
  /* padding: 0 15px; */
}
.add-lead-from-page {
  padding: 0 15px;
  width: 40%;
  margin: auto;
}
.add-lead-from h1 {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.add-lead-from form .from-group input[type="submit"] {
  background: #094e85;
  color: #fff;
  font-size: 17px;
}

.fieldStyle {
  margin-bottom: 10px;
}

.add-lead-from input[type="radio"] {
  width: 12px;
  height: 11px;
  padding: 0;
  margin-top: 7px;
  margin-bottom: 20px;
}

.add-lead-from input[type="checkbox"] {
  width: 12px;
  height: 11px;
  padding: 0;
  margin-top: 7px;
  margin-bottom: 20px;
}

.buttonss {
  width: 100% !important;
  --bs-btn-hover-bg: #0b5ed7;
}

.statusbuttons {
  width: 100px;
}

.add-lead-from input::-webkit-outer-spin-button,
.add-lead-from input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.add-lead-from input[type="number"] {
  -moz-appearance: textfield;
}

.error {
  color: red;
  font-size: 13px;
  margin-bottom: 0px;
}

.dropdownIcon::after {
  display: none;
}
.pp{
  width: 30px;
  margin-right: 6px;
}

.spinner{
  margin-top: 10px;
  /* width: 98vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row; */

}
.active .leftContainer li a {
  font-size: 0;
}
.capitalize{
  text-transform: capitalize;
}
.formGroup input {
  background: #F8FAFE;
  border: 0;
  height: 44px;
  padding: 0 19px;
  border-radius: 100px;
  min-width: 200px;
  padding-left: 50px;
}
.formGroup {
  position: relative;
  margin-left: 30px;
}
/* .formGroup button span {
  position: absolute;
  left: 22px;
  top: 7px;
} */

.formGroup button{
background: none;
color: inherit;
border: none;
padding: 0;
font: inherit;
cursor: pointer;
outline: inherit;
}
.resetButton{
margin-left: 10px;
color: #9DA1A5 !important;
border: solid #9DA1A5 1px !important;
padding: 8px 15px !important;
border-radius: 18px !important;
font-size: 10px;
}

button.SaerchTag {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.FilterBtn {
  display: flex;
}
.FilterBtn div {
  margin-right: 13px;
}
.extrafilter
{
  margin-top: 30px;
}

@media (max-width:1199px)
{
  .Tbalelayout table tr th {
    font-size: 14px;
}
.Tbalelayout table tr td {
  padding: 9px 0;
  font-size: 14px;
}
.Tbalelayout table {
  width: 150%;
}
.Tbalelayout {
  overflow-x: auto;
}
.rightContainer h2 {
  font-size: 23px;
  margin: 0 0 16px;
}
}
@media (max-width:991px)
{
.active .leftContainer li a {
  font-size: 14px;
}
.leftContainer
{
  display: none;
}
}
@media (max-width:767px)
{
  .zOuterTime {
    padding-bottom: 13px;
}
.rightContainer h2 {
  font-size: 20px;
  margin: 0 0 10px;
}
.tableTime {
  padding: 15px;
}
.Tabstable {
  padding: 14px 0 14px;
}
.Tabstable ul li a {
  font-size: 13px;
}
.Tabstable ul li {
  margin-right: 20px;
}
.Tbalelayout table tr th {
  padding: 9px 0;
  font-size: 12px;
}
.Tbalelayout table tr td {
  padding: 9px 10px 9px 0;
  font-size: 12px;
}
.Tbalelayout table {
  width: 308%;
}
}