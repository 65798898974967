body
{
    font-family: "Poppins", sans-serif !important   ;
    margin: 0 auto;
    padding: 0;
}
input
{
    outline: none;
}
p.error-msg {
    color: red !important;
    font-size: 12px !important;
    margin-bottom: 0 !important;
}


/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #555;
}