.pageContainer {
    display: flex;
    flex-wrap: wrap;
    background: #F8FAFE;
}
.rightContainer h2 {
    font-size: 30px;
    font-weight: 600;
    margin: 0 0 23px;
}

.leftContainer {
    width: 16%;
    height: calc(100vh - 72px);
    overflow: hidden;
}
.rightContainer {
    width: 84%;
    padding: 20px 15px;
    height: calc(100vh - 80px);
    overflow-x: hidden;
    overflow-y: auto;
}
.pageOuter
{
    padding: auto;
    height: 100vh;
    overflow: hidden;
}
.rejected{
    color: #FF424D;
  }

.approved{
    color: #2bc155;
  }
.pending{
  color: #FABF35;
}
.pp{
    width: 30px;
}
.tableTime {
    background: #fff;
    padding: 30px;
    border-radius: 13px;
}
.topTime {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.topTimeInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topTime .exportButton{
  margin-left: 4px;
  height: 44px;
}
.topTime h5 {
    font-size: 17px;
    font-weight: 600;
}
.topTime h5 i {
    color: #bebebe;
    font-size: 14px;
    margin-left: 3px;
}
.topTime button {
    font-size: 14px;
    color: #fff;
    border: 0;
    background: #0053CD;
    padding: 7px 15px 8px;  height: 44px;
    border-radius: 6px;
}
.topTime button img {
  margin-right: 4px;
}
.Tabstable {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0 14px;
}
.Tabstable ul {
    padding: 0;
    margin: 0;
    display: flex;
}
.Tabstable ul li {
    list-style: none;
    margin-right: 20px;
}
.Tabstable ul li a {
    font-size: 15px;
    text-decoration: none;
    color: #000000;
    font-weight: 500;
}
.Tabstable ul li.active a {
    color: #2880DA;
    font-weight: 600;
    border-bottom: 2px solid;
    padding-bottom: 3px;
}
.filters {
    display: flex;
    align-items: center;
}
.filters .Formgroup {
    margin-left: 30px;
}
.filters .Formgroup label {
    font-size: 15px;
    color: #96A5B8;
}
.filters .Formgroup select {
    font-size: 15px;
    border: 0;
    outline: none;
    color: #222B45;
    padding: 0 5px 0 2px;
}
.Tbalelayout table {
    width: 100%;
    margin-top: 6px;
}
.Tbalelayout table tr td img {
    margin-right: 7px;
}
.Tbalelayout table tr th {
    color: #989CA0;
    font-weight: 500;
    border-bottom: 1px solid #EDF2F6;
    padding: 12px 0;
    font-size: 15px;  min-width: 60px;
}
.Tbalelayout table tr td {
    border-bottom: 1px solid #EDF2F6;
    padding: 12px 0;
    font-size: 15px;  min-width: 60px;
}
.Tbalelayout table tr td label {
  padding: 5px 10px;
  font-size: 12px !important;
  font-weight: 500;
  background: #2bc15610;
  border: 1px solid #2bc155;
  border-radius: 20px;
  color: #2bc155;
}
.Tbalelayout table tr td label.Pending {
  border: 1px solid #ffc053;
  border-radius: 100px;
  color: #ffc053;
}
.Tbalelayout table tr td label.Due {
    background: #FF424D;
}
.Tbalelayout table tr td label.rejected {
  color: #FF424D;
  background: #ff424b30;
  border: 1px solid #FF424D;
}
.Tbalelayout table tr td label.pending {
    border: 1px solid #B1B1B1;
    background: #fff;
    color: #B1B1B1;
}
.OuterTime
{
    padding-bottom: 25px;
}
.revenueBox {
    background: #fff;
    border: 1px solid #EFEFEF;
    border-radius: 10px;
    padding: 15px;
}
.revenueBoxHead {
    display: flex;
    justify-content: space-between;
    min-height: 50px;
    align-items: center;
}
.revenueBoxHead h6 {
  margin: 0 0 20px 0;
  font-size: 14px;
    color: #141414;
}
.revenueBoxHead span {
    font-size: 12px;
    color: #1DBF73;
    border: 1px solid;
    padding: 4px 8px;
    border-radius: 22px;
    background: #8fe7be1f;
}
.revenueBoxFoter h4 span {
    color: #989CA0;
}
.revenueBoxFoter span {
    color: #0053CD;
    font-weight: 600;
}
.revenueBoxFoter h4 {
    font-size: 13px;
    margin: 0;
}
.revenueBoxFoter {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.revenueBox h5 {
    font-size: 26px;
    font-weight: 700;
}

.buttonss {
    width: 100% !important;
    --bs-btn-hover-bg: #0b5ed7;
  }
.statusTrue {
    background: #2BC155;
}
.statusFalse {
    background: #FF424D;
}

.dropdownIcon::after {
    display: none;
  }
   .formGroup input {
    background: #F8FAFE;
    border: 0;
    height: 44px;
    padding: 0 19px;
    border-radius: 100px;
    min-width: 200px;
    padding-left: 50px;
}
 .formGroup {
    position: relative;
    margin-left: 30px;
}
 /* .formGroup button span {
    position: absolute;
    left: 22px;
    top: 7px;
} */

.formGroup button{
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}
.resetButton{
  margin-left: 10px;
  color: #9DA1A5 !important;
  border: solid #9DA1A5 1px !important;
  padding: 8px 15px !important;
  border-radius: 18px !important;
  font-size: 10px;
}
  
.spacebetween {
    display: flex;
    width: 100% !important;
    justify-content: space-between;
  }
  
  .spinner{
    margin-top: 10px;
    /* width: 98vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row; */
  
  }
  @media (max-width:1199px)
{
  .Tbalelayout table tr th {
    font-size: 14px;
}
.Tbalelayout table tr td {
  padding: 9px 0;
  font-size: 14px;
}
.Tbalelayout table {
  width: 125%;
}
.Tbalelayout {
  overflow-x: auto;
}
.rightContainer h2 {
  font-size: 23px;
  margin: 0 0 16px;
}
}
@media (max-width:991px)
{
  
.active .leftContainerli a {
  font-size: 14px;
}

.Tabstable ul {
  flex-wrap: wrap;
}
.Tabstable ul li {
  margin-bottom: 10px;
}
.revenueBox {
  padding: 10px;
}
.revenueBoxHead h6 {
  font-size: 12px;  margin-bottom: 10px;
}
}
@media (max-width:767px)
{
    .revenueBox {
        margin: 5px 0;
    }
  .zOuterTime {
    padding-bottom: 13px;
}
.rightContainer h2 {
  font-size: 20px;
  margin: 0 0 10px;
}
.tableTime {
  padding: 15px;
}
.Tabstable {
  padding: 14px 0 14px;
}
.Tabstable ul li a {
  font-size: 13px;
}
.Tabstable ul li {
  margin-right: 20px;
}
.Tbalelayout table tr th {
  padding: 9px 0;
  font-size: 12px;
}
.Tbalelayout table tr td {
  padding: 9px 10px 9px 0;
  font-size: 12px;
}
.Tbalelayout table {
  width: 258%;
}

}