.uttrePad {
    /* padding: 0 35px 0px; */
}
.heaingFilter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0px 20px;
}
.heaingFilter h3 {
    color: #222B45;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    margin: 0;
}
.tableData
{
    background: #fff;
    border-radius: 7px;
    padding: 30px;
}
.addBtn button:first-child {
    background: #094E85;
    border: 0;
    color: #fff;
    font-size: 16px;
    padding: 7px 30px;
    border-radius: 5px;
}
.addBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 13px;
}
.addBtn button:last-child {  
    border: 0;
    font-size: 16px;
    padding: 7px 15px;
    border-radius: 5px;
    color: #fff;
    margin-left: 14px;
}
.activeProfile{
    background-image: linear-gradient(#74D680, #378B29);
}
.deactiveProfile{
    background-image: linear-gradient(#FF0000, #ff7878);

}
.leftProfile h4 {
    font-size: 24px;
    margin: 11px 0 5px;
}
.leftProfile h5 {
    font-size: 14px;
    color: #000000ab;
    margin-bottom:3px;
}
.stars {
    display: flex;
    align-items: center;
}
.stars span {
    color: #FABF35;
    margin-right: 2px;
}
.stars label {
    font-size: 14px;
    color: #000000B2;
    margin-left: 3px;
}
.leftProfile h6 {
    font-size: 14px;
    color: #292929;
    margin-top: 5px;
}
.leftProfile h6 span {
    color: #FABF35;
}
.rightProfile h4 {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
}
.rightProfile p {
    font-size: 15px;
    max-width: 500px;
    margin-bottom: 24px;
}
.leftProfile {
    border-right: 1px solid #ddd;
}
.rightProfile {
    padding-left: 15px;
}
.rightProfile p:last-child {
    margin: 0;
}
.outTabl {
    background: #fff;
}
.purchaseCorse h3 {
    font-size: 18px;
    color: #000000;
    padding: 0 30px 6px;
}
.purchaseCorse table tr th {
    background: #F4F7F9;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 8px 31px;
    text-align: center;
    font-size: 13px;
    color: #0000008a;
    font-weight: 500;
}
.purchaseCorse table tr td {
    padding: 11px 31px;
    font-size: 16px;
    color: #000000;
}
.purchaseCorse table tr td img {
    margin-right: 8px;
}
.purchaseCorse table
{
    width: 100%;
}
.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 20px;
    margin: 0px 6px 0 0;
    top: 2px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
       background-color: #d8d8d8;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 3px;
    bottom: 3px;
    background-color: #fff;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #0c4e85;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(9px);
    -ms-transform: translateX(9px);
    transform: translateX(9px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .purchaseCorse table tr td span.scBtn {
    border-radius: 4px;
    border: 1px solid #64D4B0;
    background: var(--background-mode, #FFF);
    color: #64D4B0;
    padding: 12px 19px;
    min-width: 100px;
    display: flex;
    float: left;
    text-align: center;
    justify-content: center;
}

.add-course h2 {
    font-size: 23px;
    text-align: center;
    margin: 20px 0 30px;
    font-weight: 700;
}
.add-course select {
    height: 43px;
    border: 1px solid #094E85;
    border-radius: 6px;
    font-size: 14px;
    color: #094E85;
    width: 100%;
    margin-bottom: 8px;
    padding: 0 11px;
}
.add-course input {
    height: 43px;
    border: 1px solid #094E85;
    border-radius: 6px;
    font-size: 14px;
    color: #094E85;
    width: 100%;
    margin-bottom: 8px;
    padding: 0 11px;
}
.add-course .addStrip span {
    border: 1px dashed #094E85;
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    padding: 10px 0;
    color: #094E85;
    pointer-events: none;
    font-size: 12px;
    background: #fff;
    border-radius: 5px;
}
.add-course button {
    height: 43px;
    width: 100%;
    border: 0;
    border-radius: 5px;
    margin: 26px 0px 30px;
    color: #fff;
    font-size: 18px;
    background: #0c4e85;
}
.add-course .addStrip input {
    position: absolute;
    top: 0;
    height: 40px;
    width: 100%;
    font-size: 0;
    padding: 10px;
}
.addStrip
{
    position: relative;
}
.add-course input::-webkit-outer-spin-button,
.add-course input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.add-course input[type=number] {
  -moz-appearance: textfield;
}